<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataCurrencies",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'pair':
        case 'pair-blockchains':
        case 'currency-root':
        case 'currency-blockchain':
          this.wsCurrency(data['currency']);
          break;
        case 'global-pair-list':
        case 'global-pair-root':
        case 'global-pair-exchange':
        case 'global-pair-indicator':
        case 'global-pair-calc':
        case 'global-pair-data':
        case 'global-pair-graph':
        case 'global-currency-list':
        case 'exchange-currency':
        case 'exchange-pair':
        case 'currency-list':
        case 'main':
        case 'pairs':
        case 'calc-info':
          this.wsCurrencies({currencies: data['currencies']});
          break;
        case 'currency-create':
        case 'currency-create-success':
          this.wsCurrenciesCreate({
            idCurrency: data.idCurrency,
            code: data.code,
          });
          break;
        case 'currency-update':
        case 'currency-update-success':
          this.wsCurrenciesUpdate({
            idCurrency: data.idCurrency,
            code: data.code,
          });
          break;
        case 'currency-delete':
        case 'currency-delete-success':
          this.wsCurrenciesDelete({
            idCurrency: data.idCurrency,
          });
          break;
        case 'currency-lock':
        case 'currency-lock-success':
          this.wsCurrenciesLock({
            idCurrency: data.idCurrency,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsCurrency',
      'wsCurrencies',
      'wsCurrenciesCreate',
      'wsCurrenciesUpdate',
      'wsCurrenciesDelete',
      'wsCurrenciesLock',
    ]),
  },
}
</script>
