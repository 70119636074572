<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataCountries",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'language-country-root':
        case 'language-country-name':
          this.wsCountry({country: data['country']});
          break;
        case 'language-country-list':
        case 'language-locale-list':
          this.wsCountries({countries: data['countries']});
          break;
        case 'language-country-create':
        case 'language-country-create-success':
          this.wsCountriesCreate({
            idCountry: data.idCountry,
            code: data.code,
            currencySymbol: data.currencySymbol,
            idLocaleDefault: data.idLocaleDefault,
          });
          break;
        case 'language-country-update':
        case 'language-country-update-success':
          this.wsCountriesUpdate({
            idCountry: data.idCountry,
            code: data.code,
            currencySymbol: data.currencySymbol,
            idLocaleDefault: data.idLocaleDefault,
          });
          break;
        case 'language-country-delete':
        case 'language-country-delete-success':
          this.wsCountriesDelete({
            idCountry: data.idCountry,
          });
          break;
        case 'language-country-lock':
        case 'language-country-lock-success':
          this.wsCountriesLock({
            idCountry: data.idCountry,
          });
          break;
        default:
          if (typeof data['countries'] != 'undefined') {
            this.wsCountries({countries: data['countries']});
          }
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsCountry',
      'wsCountries',
      'wsCountriesCreate',
      'wsCountriesUpdate',
      'wsCountriesDelete',
      'wsCountriesLock',
    ]),
  },
}
</script>
