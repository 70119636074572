<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataPricePairs",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'store-exchange-info':
        case 'store-info':
        case 'store-sync':
        case 'stream-info':
        case 'stream-exchange-info':
          this.wsPricePairs({pricePairs: data['pricePairs']});
          break;
        case 'store-pair-time-start-update':
          this.wsPricePairsUpdateTimeStart({
            indexPair: data['indexPair'],
            timeStart: data['timeStart'],
          });
          break;
        case 'store-pair-time-store-update':
          this.wsPricePairsUpdateTimeStore({
            indexPair: data['indexPair'],
            timeStore: data['timeStore'],
          });
          break;
        case 'store-pair-valid-update':
          this.wsPricePairsUpdateValid({
            indexPair: data['indexPair'],
            valid: data['valid'],
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
        'wsPricePairs',
        'wsPricePairsUpdateValid',
        'wsPricePairsUpdateTimeStart',
        'wsPricePairsUpdateTimeStore',
    ]),
  },
}
</script>
