<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataGlobalPairExchanges",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'global-pair-exchange':
        case 'pair':
          this.wsGlobalPairExchanges({globalPairExchanges: data['globalPairExchanges']});
          break;
        case 'global-pair-exchange-add':
        case 'global-pair-exchange-add-success':
          this.wsGlobalPairExchangesAdd({
            idGlobalPair: data.idGlobalPair,
            idExchange: data.idExchange,
            usage: data.usage,
          });
          break;
        case 'global-pair-exchange-update':
        case 'global-pair-exchange-update-success':
          this.wsGlobalPairExchangesUpdate({
            idGlobalPair: data.idGlobalPair,
            idExchange: data.idExchange,
            usage: data.usage,
          });
          break;
        case 'global-pair-exchange-delete':
        case 'global-pair-exchange-delete-success':
          this.wsGlobalPairExchangesDelete({
            idGlobalPair: data.idGlobalPair,
            idExchange: data.idExchange,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsGlobalPairExchanges',
      'wsGlobalPairExchangesAdd',
      'wsGlobalPairExchangesUpdate',
      'wsGlobalPairExchangesDelete',
    ]),
  },
}
</script>
