<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataPriceCurrencies",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'store-exchange-info':
        case 'store-info':
        case 'store-sync':
        case 'stream-info':
        case 'stream-exchange-info':
          this.wsPriceCurrencies({priceCurrencies: data['priceCurrencies']});
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsPriceCurrencies',
    ]),
  },
}
</script>
