<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataBlockchains",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'currency-list':
        case 'blockchain-list':
        case 'pair':
        case 'currency-blockchain':
        case 'helper-wallets':
        case 'pair-blockchains':
          this.wsBlockchains({blockchains: data['blockchains']});
          break;
        case 'blockchain-create':
        case 'blockchain-create-success':
          this.wsBlockchainsCreate({
            idBlockchain: data.idBlockchain,
            name: data.name,
            preg: data.preg,
            tag: data.tag,
          });
          break;
        case 'blockchain-update':
        case 'blockchain-update-success':
          this.wsBlockchainsUpdate({
            idBlockchain: data.idBlockchain,
            name: data.name,
            preg: data.preg,
            tag: data.tag,
          });
          break;
        case 'blockchain-delete':
        case 'blockchain-delete-success':
          this.wsBlockchainsDelete({
            idBlockchain: data.idBlockchain,
          });
          break;
        case 'blockchain-lock':
        case 'blockchain-lock-success':
          this.wsBlockchainsLock({
            idBlockchain: data.idBlockchain,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsBlockchains',
      'wsBlockchainsCreate',
      'wsBlockchainsUpdate',
      'wsBlockchainsDelete',
      'wsBlockchainsLock',
    ]),
  },
}
</script>
