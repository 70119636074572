<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataGlobalPairs",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'global-pair-root':
        case 'global-pair-calc':
        case 'global-pair-data':
        case 'global-pair-exchange':
        case 'global-pair-graph':
        case 'global-pair-indicator':
        case 'pair':
        case 'pair-graph':
        case 'pair-blockchains':
          this.wsGlobalPair({
            idGlobalPair: parseInt(data.globalPair.idGlobalPair),
            idGlobalCurrency: parseInt(data.globalPair.idGlobalCurrency),
            idCurrency: parseInt(data.globalPair.idCurrency),
            showOnMainPage: data.globalPair.showOnMainPage,
            locked: data.globalPair.locked,
            lastCandleTimes: data.globalPair.lastCandleTimes,
            lastAiCandleDiffTimes: data.globalPair.lastAiCandleDiffTimes,
            lastIndicatorTimes: data.globalPair.lastIndicatorTimes,
            lastAiIndicatorTimes: data.globalPair.lastAiIndicatorTimes,
            timeStart: data.globalPair.timeStart,
          });
          break;
        case 'pair-update':
        case 'global-pair-data-update':
          this.wsGlobalPairDataUpdate({
            idGlobalPair: parseInt(data.idGlobalPair),
            candlesLast: data.candlesLast ?? [],
            aiCandlesDiffLast: data.aiCandlesDiffLast ?? [],
            indicatorsLast: data.indicatorsLast ?? [],
            aiIndicatorsLast: data.aiIndicatorsLast ?? [],
          });
          break;
        case 'calc-update':
          this.wsGlobalPairCalcUpdate({
            candlesLast: data.candlesLast ?? [],
            aiCandlesDiffLast: data.aiCandlesDiffLast ?? [],
            indicatorsLast: data.indicatorsLast ?? [],
            aiIndicatorsLast: data.aiIndicatorsLast ?? [],
          });
          break;
        case 'global-pair-list':
        case 'main':
        case 'pairs':
        case 'calc-info':
          this.wsGlobalPairs({globalPairs: data['globalPairs']});
          this.wsGlobalPairCalcUpdate({
            candlesLast: data.candlesLast ?? [],
            aiCandlesDiffLast: data.aiCandlesDiffLast ?? [],
            indicatorsLast: data.indicatorsLast ?? [],
            aiIndicatorsLast: data.aiIndicatorsLast ?? [],
          });
          break;
        case 'global-pair-add': // TODO: rename to 'create'
        case 'global-pair-add-success':
          this.wsGlobalPairsAdd({
            idGlobalPair: data.idGlobalPair,
            idGlobalCurrency: data.idGlobalCurrency,
            idCurrency: data.idCurrency,
          });
          break;
        case 'global-pair-root-update':
        case 'global-pair-root-update-success':
          this.wsGlobalPairsRootUpdate({
            idGlobalPair: data.idGlobalPair,
            showOnMainPage: data.showOnMainPage,
          });
          break;
        case 'global-pair-delete':
        case 'global-pair-delete-success':
          this.wsGlobalPairsDelete({
            idGlobalPair: data.idGlobalPair,
          });
          break;
        case 'global-pair-lock':
        case 'global-pair-lock-success':
          this.wsGlobalPairsLock({
            idGlobalPair: data.idGlobalPair,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsGlobalPair',
      'wsGlobalPairDataUpdate',
      'wsGlobalPairCalcUpdate',
      'wsGlobalPairs',
      'wsGlobalPairsAdd',
      'wsGlobalPairsRootUpdate',
      'wsGlobalPairsDelete',
      'wsGlobalPairsLock',
    ]),
  },
}
</script>
