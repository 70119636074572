<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataBackups",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'indicator-list':
        case 'indicator-root':
        case 'indicator-param-list':
        case 'indicator-out-list':
        case 'indicator-set-list':
          this.wsMath({
            math: data['math'],
            types: data['types'],
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsMath',
    ]),
  },
}
</script>
