<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataExchangePairs",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'exchange-pair':
        case 'global-pair-exchange':
        case 'pair':
          this.wsExchangePairs({exchangePairs: data['exchangePairs']});
          break;
        case 'exchange-pair-add': // TODO: rename to 'create'
        case 'exchange-pair-add-success':
          this.wsExchangePairsCreate({
            idExchangePair: data.idExchangePair,
            idExchange: data.idExchange,
            idCurrencyPrimary: data.idCurrencyPrimary,
            idCurrencySecondary: data.idCurrencySecondary,
            offset: data.offset,
          });
          break;
        case 'exchange-pair-delete':
        case 'exchange-pair-delete-success':
          this.wsExchangePairsDelete({
            idExchangePair: data.idExchangePair,
          });
          break;
        case 'exchange-pair-lock':
        case 'exchange-pair-lock-success':
          this.wsExchangePairsLock({
            idExchangePair: data.idExchangePair,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsExchangePairs',
      'wsExchangePairsCreate',
      'wsExchangePairsDelete',
      'wsExchangePairsLock',
    ]),
  },
}
</script>
