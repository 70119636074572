<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataUserWallets",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'helper-wallets':
          this.wsUserWallets({userWallets: data['userWallets']});
          break;
        case 'helper-wallet-create':
        case 'helper-wallet-create-success':
          this.wsUserWalletsCreate({
            idUserWallet: data.idUserWallet,
            idUser: data.idUser,
            idBlockchain: data.idBlockchain,
            address: data.address,
            tag: data.tag,
          });
          break;
        case 'helper-wallet-update':
        case 'helper-wallet-update-success':
          this.wsUserWalletsUpdate({
            idUserWallet: data.idUserWallet,
            address: data.address,
            tag: data.tag,
          });
          break;
        case 'helper-wallet-delete':
        case 'helper-wallet-delete-success':
          this.wsUserWalletsDelete({
            idUserWallet: data.idUserWallet,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsUserWallets',
      'wsUserWalletsCreate',
      'wsUserWalletsUpdate',
      'wsUserWalletsDelete',
    ]),
  },
}
</script>
