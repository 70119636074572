<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataExchangeCurrencies",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'exchange-pair':
        case 'exchange-currency':
          this.wsExchangeCurrencies({exchangeCurrencies: data['exchangeCurrencies']});
          break;
        case 'exchange-currency-add': // TODO: rename to 'create'
        case 'exchange-currency-add-success':
          this.wsExchangeCurrenciesAdd({
            idExchangeCurrency: data.idExchangeCurrency,
            idExchange: data.idExchange,
            idCurrency: data.idCurrency,
          });
          break;
        case 'exchange-currency-delete':
        case 'exchange-currency-delete-success':
          this.wsExchangeCurrenciesDelete({
            idExchangeCurrency: data.idExchangeCurrency,
          });
          break;
        case 'exchange-currency-lock':
        case 'exchange-currency-lock-success':
          this.wsExchangeCurrenciesLock({
            idExchangeCurrency: data.idExchangeCurrency,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsExchangeCurrencies',
      'wsExchangeCurrenciesAdd',
      'wsExchangeCurrenciesDelete',
      'wsExchangeCurrenciesLock',
    ]),
  },
}
</script>
