<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataIndicatorParams",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'indicator-set-list':
        case 'indicator-param-list':
        case 'pair':
          this.wsIndicatorParams({indicatorParams: data['indicatorParams']});
          break;
        case 'indicator-param-create':
        case 'indicator-param-create-success':
          this.wsIndicatorParamsCreate({
            idIndicatorParam: data.idIndicatorParam,
            idIndicator: data.idIndicator,
            name: data.name,
            type: data.type,
            index: data.index,
            defaultValue: data.defaultValue,
          });
          break;
        case 'indicator-param-update':
        case 'indicator-param-update-success':
          this.wsIndicatorParamsUpdate({
            idIndicatorParam: data.idIndicatorParam,
            idIndicator: data.idIndicator,
            name: data.name,
            type: data.type,
            defaultValue: data.defaultValue,
          });
          break;
        case 'indicator-param-delete':
        case 'indicator-param-delete-success':
          this.wsIndicatorParamsDelete({
            idIndicatorParam: data.idIndicatorParam,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsIndicatorParams',
      'wsIndicatorParamsCreate',
      'wsIndicatorParamsUpdate',
      'wsIndicatorParamsDelete',
    ]),
  },
}
</script>
