<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataIndicators",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'indicator-root':
        case 'indicator-out-list':
        case 'indicator-set-list':
        case 'indicator-param-list':
          this.wsIndicatorRoot({
            idIndicator: data.indicator.idIndicator,
            name: data.indicator.name,
            math: data.indicator.math,
            locked: data.indicator.locked,
          });
          break;
        case 'indicator-list':
        case 'global-pair-indicator':
        case 'pair':
        case 'calc-info':
          this.wsIndicators({indicators: data['indicators']});
          break;
        case 'indicator-create':
        case 'indicator-create-success':
          this.wsIndicatorsCreate({
            idIndicator: data.idIndicator,
            name: data.name,
            math: data.math,
          });
          break;
        case 'indicator-update':
        case 'indicator-update-success':
          this.wsIndicatorsUpdate({
            idIndicator: data.idIndicator,
            name: data.name,
            math: data.math,
          });
          break;
        case 'indicator-delete':
        case 'indicator-delete-success':
          this.wsIndicatorsDelete({
            idIndicator: data.idIndicator,
          });
          break;
        case 'indicator-lock':
        case 'indicator-lock-success':
          this.wsIndicatorsLock({
            idIndicator: data.idIndicator,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsIndicatorRoot',
      'wsIndicators',
      'wsIndicatorsCreate',
      'wsIndicatorsUpdate',
      'wsIndicatorsDelete',
      'wsIndicatorsLock',
    ]),
  },
}
</script>
