<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataUserAccounts",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'helper-accounts':
          this.wsUserAccounts({userAccounts: data['userAccounts']});
          if (typeof data['userAccountTypes'] != 'undefined') {
            this.wsUserAccountTypes({userAccountTypes: data['userAccountTypes']});
          }
          break;
        case 'helper-account-create':
        case 'helper-account-create-success':
          this.wsUserAccountsCreate({
            idUserAccount: data.idUserAccount,
            idUser: data.idUser,
            type: data.type,
            title: data.title,
          });
          break;
        case 'helper-account-update':
        case 'helper-account-update-success':
          this.wsUserAccountsUpdate({
            idUserAccount: data.idUserAccount,
            type: data.type,
            title: data.title,
          });
          break;
        case 'helper-account-delete':
        case 'helper-account-delete-success':
          this.wsUserAccountsDelete({
            idUserAccount: data.idUserAccount,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsUserAccounts',
      'wsUserAccountsCreate',
      'wsUserAccountsUpdate',
      'wsUserAccountsDelete',
      'wsUserAccountTypes',
    ]),
  },
}
</script>
