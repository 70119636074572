<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataExchanges",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'exchange-currency':
        case 'exchange-pair':
        case 'exchange-root':
          this.wsExchangeRoot({
            idExchange: data['exchange']['idExchange'],
            code: data['exchange']['code'],
            locked: data['exchange']['locked'],
            blockStore: data['exchange']['blockStore'],
            blockStream: data['exchange']['blockStream'],
          });
          break;
        case 'global-pair-exchange':
        case 'exchange-list':
        case 'pair':
        case 'stream-info':
        case 'stream-exchange-info':
        case 'store-info':
          this.wsExchanges({exchanges: data['exchanges']});
          break;
        case 'exchange-create':
        case 'exchange-create-success':
          this.wsExchangesCreate({
            idExchange: data.idExchange,
            code: data.code,
          });
          break;
        case 'exchange-update':
        case 'exchange-update-success':
          this.wsExchangesUpdate({
            idExchange: data.idExchange,
            code: data.code,
          });
          break;
        case 'exchange-status':
        case 'exchange-status-success':
          this.wsExchangesStatus({
            idExchange: data.idExchange,
            blockStore: data.blockStore,
            blockStream: data.blockStream,
          });
          break;
        case 'exchange-delete':
        case 'exchange-delete-success':
          this.wsExchangesDelete({
            idExchange: data.idExchange,
          });
          break;
        case 'exchange-lock':
        case 'exchange-lock-success':
          this.wsExchangesLock({
            idExchange: data.idExchange,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsExchangeRoot',
      'wsExchanges',
      'wsExchangesCreate',
      'wsExchangesUpdate',
      'wsExchangesStatus',
      'wsExchangesDelete',
      'wsExchangesLock',
    ]),
  },
}
</script>
