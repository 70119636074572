<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataCurrencyBlockchains",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'currency-blockchain':
          this.wsCurrencyBlockchains({currencyBlockchains: data['currencyBlockchains']});
          break;
        case 'currency-blockchain-create':
        case 'currency-blockchain-create-success':
          this.wsCurrencyBlockchainsCreate({
            idCurrency: data.idCurrency,
            idBlockchain: data.idBlockchain,
            contract: data.contract,
            main: data.main,
          });
          break;
        case 'currency-blockchain-update':
        case 'currency-blockchain-update-success':
          this.wsCurrencyBlockchainsUpdate({
            idCurrency: data.idCurrency,
            idBlockchain: data.idBlockchain,
            contract: data.contract,
            main: data.main,
          });
          break;
        case 'currency-blockchain-delete':
        case 'currency-blockchain-delete-success':
          this.wsCurrencyBlockchainsDelete({
            idCurrency: data.idCurrency,
            idBlockchain: data.idBlockchain,
          });
          break;
        case 'currency-blockchain-lock':
        case 'currency-blockchain-lock-success':
          this.wsCurrencyBlockchainsLock({
            idCurrency: data.idCurrency,
            idBlockchain: data.idBlockchain,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsCurrencyBlockchains',
      'wsCurrencyBlockchainsCreate',
      'wsCurrencyBlockchainsUpdate',
      'wsCurrencyBlockchainsDelete',
      'wsCurrencyBlockchainsLock',
    ]),
  },
}
</script>
