<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataGlobalPairIndicatorSets",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'global-pair-indicator':
        case 'pair':
        case 'calc-info':
          this.wsGlobalPairIndicatorSets({globalPairIndicatorSets: data['globalPairIndicatorSets']});
          break;
        case 'global-pair-indicator-set-add':
        case 'global-pair-indicator-set-add-success':
          this.wsGlobalPairIndicatorSetsAdd({
            idGlobalPair: data.idGlobalPair,
            idIndicatorSet: data.idIndicatorSet,
          });
          break;
        case 'global-pair-indicator-set-delete':
        case 'global-pair-indicator-set-delete-success':
          this.wsGlobalPairIndicatorSetsDelete({
            idGlobalPair: data.idGlobalPair,
            idIndicatorSet: data.idIndicatorSet,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsGlobalPairIndicatorSets',
      'wsGlobalPairIndicatorSetsAdd',
      'wsGlobalPairIndicatorSetsDelete',
    ]),
  },
}
</script>
