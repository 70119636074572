<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataIndicatorOuts",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'indicator-out-list':
        case 'pair':
          this.wsIndicatorOuts({indicatorOuts: data['indicatorOuts']});
          break;
        case 'indicator-out-create':
        case 'indicator-out-create-success':
          this.wsIndicatorOutsCreate({
            idIndicatorOut: data.idIndicatorOut,
            idIndicator: data.idIndicator,
            name: data.name,
            index: data.index,
          });
          break;
        case 'indicator-out-update':
        case 'indicator-out-update-success':
          this.wsIndicatorOutsUpdate({
            idIndicatorOut: data.idIndicatorOut,
            idIndicator: data.idIndicator,
            name: data.name,
          });
          break;
        case 'indicator-out-delete':
        case 'indicator-out-delete-success':
          this.wsIndicatorOutsDelete({
            idIndicatorOut: data.idIndicatorOut,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsIndicatorOuts',
      'wsIndicatorOutsCreate',
      'wsIndicatorOutsUpdate',
      'wsIndicatorOutsDelete',
    ]),
  },
}
</script>
