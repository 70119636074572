<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataSettings",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'setting-list':
          this.wsSettings({settings: data['settings']});
          break;
        case 'setting-create':
        case 'setting-create-success':
          this.wsSettingsCreate({
            idSetting: data.idSetting,
            setting: data.setting,
            name: data.name,
            type: data.type,
            value: data.value,
          });
          break;
        case 'setting-update':
        case 'setting-update-success':
          this.wsSettingsUpdate({
            idSetting: data.idSetting,
            value: data.value,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsSettings',
      'wsSettingsCreate',
      'wsSettingsUpdate',
    ]),
  },
}
</script>
