<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataGlobalCurrencies",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'global-pair-list':
        case 'global-pair-root':
        case 'global-pair-exchange':
        case 'global-pair-indicator':
        case 'global-pair-calc':
        case 'global-pair-data':
        case 'global-pair-graph':
        case 'global-currency-list':
        case 'main':
        case 'pairs':
        case 'pair':
        case 'calc-info':
          this.wsGlobalCurrencies({globalCurrencies: data['globalCurrencies']});
          break;
        case 'global-currency-add':
        case 'global-currency-add-success':
          this.wsGlobalCurrenciesAdd({
            idCurrency: data.idCurrency,
            idGlobalCurrency: data.idGlobalCurrency,
          });
          break;
        case 'global-currency-delete':
        case 'global-currency-delete-success':
          this.wsGlobalCurrenciesDelete({
            idGlobalCurrency: data.idGlobalCurrency,
          });
          break;
        case 'global-currency-lock':
        case 'global-currency-lock-success':
          this.wsGlobalCurrenciesLock({
            idGlobalCurrency: data.idGlobalCurrency,
          });
          break;
        case 'global-currency-currency-add':
        case 'global-currency-currency-add-success':
          this.wsGlobalCurrenciesCurrencyAdd({
            idCurrency: data.idCurrency,
            idGlobalCurrency: data.idGlobalCurrency,
          });
          break;
        case 'global-currency-currency-delete':
        case 'global-currency-currency-delete-success':
          this.wsGlobalCurrenciesCurrencyDelete({
            idCurrency: data.idCurrency,
            idGlobalCurrency: data.idGlobalCurrency,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsGlobalCurrencies',
      'wsGlobalCurrenciesAdd', // TODO: rename everywhere to "create"
      'wsGlobalCurrenciesDelete',
      'wsGlobalCurrenciesLock',
      'wsGlobalCurrenciesCurrencyAdd',
      'wsGlobalCurrenciesCurrencyDelete',
    ]),
  },
}
</script>
