<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataIndicatorSets",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'indicator-set-list':
        case 'global-pair-indicator':
        case 'pair':
        case 'calc-info':
          this.wsIndicatorSets({indicatorSets: data['indicatorSets']});
          break;
        case 'indicator-set-create':
        case 'indicator-set-create-success':
          this.wsIndicatorSetsCreate({
            idIndicatorSet: data.idIndicatorSet,
            idIndicator: data.idIndicator,
            values: data.values,
          });
          break;
        case 'indicator-set-update':
        case 'indicator-set-update-success':
          this.wsIndicatorSetsUpdate({
            idIndicatorSet: data.idIndicatorSet,
            idIndicator: data.idIndicator,
            locked: data.locked,
            values: data.values,
          });
          break;
        case 'indicator-set-delete':
        case 'indicator-set-delete-success':
          this.wsIndicatorSetsDelete({
            idIndicatorSet: data.idIndicatorSet,
          });
          break;
        case 'indicator-set-lock':
        case 'indicator-set-lock-success':
          this.wsIndicatorSetsLock({
            idIndicatorSet: data.idIndicatorSet,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsIndicatorSets',
      'wsIndicatorSetsCreate',
      'wsIndicatorSetsUpdate',
      'wsIndicatorSetsDelete',
      'wsIndicatorSetsLock',
    ]),
  },
}
</script>
