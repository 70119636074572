<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataTradeGroups",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'trade-group-list':
          this.wsTradeGroups({tradeGroups: data['tradeGroups']});
          break;
        case 'trade-group-create':
        case 'trade-group-create-success':
          this.wsTradeGroupsCreate({
            idTradeGroup: data.idTradeGroup,
            name: data.name,
            telegram: data.telegram,
            timeframes: data.timeframes,
          });
          break;
        case 'trade-group-update':
        case 'trade-group-update-success':
          this.wsTradeGroupsUpdate({
            idTradeGroup: data.idTradeGroup,
            name: data.name,
            telegram: data.telegram,
            timeframes: data.timeframes,
            locked: data.locked, // TODO: remove it
          });
          break;
        case 'trade-group-delete':
        case 'trade-group-delete-success':
          this.wsTradeGroupsDelete({
            idTradeGroup: data.idTradeGroup,
          });
          break;
        case 'trade-group-lock':
        case 'trade-group-lock-success':
          this.wsTradeGroupsLock({
            idTradeGroup: data.idTradeGroup,
          });
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsTradeGroups',
      'wsTradeGroupsCreate',
      'wsTradeGroupsUpdate',
      'wsTradeGroupsDelete',
      'wsTradeGroupsLock',
    ]),
  },
}
</script>
