<template>
  <div/>
</template>

<script>
import {latestMessage} from "@/plugins/ws";
import {mapActions} from "vuex";

export default {
  name: "dataPriceExchanges",
  data() {
    return {
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'store-exchange-info':
        case 'stream-exchange-info':
          this.wsPriceExchange({
            indexExchange: data['priceExchange']['indexExchange'],
            code: data['priceExchange']['code'],
          });
          break;
        case 'store-info':
        case 'store-sync':
        case 'stream-info':
          this.wsPriceExchanges({priceExchanges: data['priceExchanges']});
          break;
      }
    },
  },
  methods: {
    ...mapActions([
      'wsPriceExchange',
      'wsPriceExchanges',
    ]),
  },
}
</script>
